// eslint-env es6

import { getProvider } from 'externalsystems/providers';

/**
 * Forwards authorization to regsitered implementation
 */
function authorize({ provider, authtype, context = {} }) {
    const implementation = getProvider(provider);
    return implementation.authorize({ provider, authtype, context });
}

export { authorize };
