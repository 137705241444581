import { IIssueCalendarInfo, IIssueCalendarInfoDay } from '@pressreader/content-types';

import { IIssueCalendarInfoDayDto, IIssueCalendarInfoDto } from '../types/issue.calendar';

function mapIssueCalendarInfoDay(date: Date, dto: IIssueCalendarInfoDayDto): IIssueCalendarInfoDay {
    return {
        disabledForPurchase: dto.Dis === 1,
        paid: dto.P === 1,
        issueDate: date,
        version: dto.V,
    };
}

function mapIssueCalendarInfo(dto: IIssueCalendarInfoDto): IIssueCalendarInfo {
    return {
        highlightPaidIssues: dto.EnableHighlihtPaidIssues,
        enableCalendarView: dto.EnableCalendarView,
        enableFullDateView: dto.EnableFullDateView,
        enableListView: dto.EnableListView,
        years: Object.keys(dto.Years)
            .map(s => Number.parseInt(s, 10))
            .reduce<Record<number, Record<number, Record<number, IIssueCalendarInfoDay>>>>((yearAcc, yearKey) => {
                const yearMap = dto.Years[yearKey];
                yearAcc[yearKey] = Object.keys(yearMap).reduce<Record<string, Record<string, IIssueCalendarInfoDay>>>((monthAcc, monthKey) => {
                    const monthMap = yearMap[parseInt(monthKey, 10)];
                    monthAcc[monthKey] = Object.keys(monthMap).reduce<Record<string, IIssueCalendarInfoDay>>((dayAcc, dayKey) => {
                        const day = parseInt(dayKey, 10);
                        const issueDate = new Date(yearKey, parseInt(monthKey, 10) - 1, day);
                        dayAcc[dayKey] = mapIssueCalendarInfoDay(issueDate, monthMap[day]);
                        return dayAcc;
                    }, {});
                    return monthAcc;
                }, {});
                return yearAcc;
            }, {}),
    };
}

export { mapIssueCalendarInfo };
