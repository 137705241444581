// eslint-env es6

import { authorize } from 'externalsystems/auth.provider';
import { EXTERNAL_AUTH_TYPE } from '@pressreader/authentication-types';

class DefaultSignInService {
    constructor(provider) {
        if (!provider) {
            throw new Error('External provider name required');
        }

        this._provider = provider;
    }

    signIn({ context }) {
        return authorize({ provider: this._provider, authtype: EXTERNAL_AUTH_TYPE.SIGNUP, context });
    }
}

export default DefaultSignInService;
