/* eslint-env es6 */
/* eslint-disable */
import * as $ from 'jquery';
import { padStart } from 'lodash';
import ndAuth from 'authentication/nd.authentication';
import ndPurchase from 'purchase/nd.purchase';
import ndEvent from '@pressreader/src/nd.event';
import ndViewerApp from 'nd.viewer.app';
import { Deferred } from '@pressreader/src/promise/deferred';

var _deferredLoad, _provider, _issuesCalendarData, _cid,
    _calendarUpdateEventName = "calendar-updated",
    issuesCalendar = {}, _issuesDates;

ndAuth.onBeforeAccountChanged.subscribe(function () { _deferredLoad = undefined; });

function load(cid, reload) {
    if (!_deferredLoad || ((_cid != cid || reload) && _deferredLoad.state() !== "pending")) {
        _deferredLoad = new Deferred();
        _cid = cid;
        _provider.load(cid).then(function (data) {

            // make sure cid is same
            if (_cid !== cid) {
                return;
            }

            if (data) {
                _issuesCalendarData = data;
                _issuesDates = flattenIssuesDates(data.Years);
            }

            if (_deferredLoad) {
                _deferredLoad.resolve(data);
            }

            ndEvent.trigger(_calendarUpdateEventName, issuesCalendar, data);
        })
            .catch(_deferredLoad.reject);
    }

    return _deferredLoad.promise();
}

function flattenIssuesDates(data, result) {
    let issuesDates = [];

    for (const i in data) {
        if (isNaN(i)) {
            return [result];
        }

        const flattenedIssuesDates = flattenIssuesDates(data[i], (result || "") + padStart(i, 2, '0'));

        issuesDates = flattenedIssuesDates.concat(issuesDates);
    }

    return issuesDates;
}

issuesCalendar = $.extend(issuesCalendar, {
    setProvider: function (provider) {
        _provider = provider;
        return this;
    },
    loaded: function () {
        if (_deferredLoad) {
            return _deferredLoad;
        }
        return Promise.reject();
    },
    getData: function () {
        return _issuesCalendarData;
    },
    getIssuesDates: function () {
        return _issuesDates;
    },
    load: load,
    calendarUpdatedEvent: _calendarUpdateEventName
});

$.nd.viewer.pages.loadedChange(function (e) {
    if (e && e.value && ndViewerApp) {
        issuesCalendar.load(ndViewerApp.cid());
    }
});

ndEvent.bind(ndPurchase, "stateChanged", function () {
    var reload = true;
    load(ndViewerApp.cid(), reload);
});

$.nd.issuesCalendar = issuesCalendar;
export default issuesCalendar;
