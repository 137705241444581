// eslint-env es6

import { registerProvider } from 'externalsystems/providers';
import { ExternalProvider } from '@pressreader/authentication-types';

import DefaultProvider from 'externalsystems/providers/default.provider';
import InstapaperProvider from 'externalsystems/providers/instapaper.provider';

registerProvider(ExternalProvider.FACEBOOK, new DefaultProvider());
registerProvider(ExternalProvider.TWITTER, new DefaultProvider());
registerProvider(ExternalProvider.GOOGLE, new DefaultProvider());
registerProvider(ExternalProvider.EVERNOTE, new DefaultProvider());
registerProvider(ExternalProvider.INSTAPAPER, new InstapaperProvider());
registerProvider(ExternalProvider.ONENOTE, new DefaultProvider());
registerProvider(ExternalProvider.BELL, new DefaultProvider());
registerProvider(ExternalProvider.PIANO, new DefaultProvider());
registerProvider(ExternalProvider.OPENIDCONNECT, new DefaultProvider());
registerProvider(ExternalProvider.SAML, new DefaultProvider());
registerProvider(ExternalProvider.APPLE, new DefaultProvider());
registerProvider(ExternalProvider.AUTH0, new DefaultProvider());
