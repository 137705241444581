import issuesCalendar from "nd.issuescalendar";
import services from "nd.services.api";

    

    var provider = {};

    provider.load = function (cid) {
    	return services.get("calendar", "get", { cid: cid });
    };

    issuesCalendar.setProvider(provider);

	export default provider;

