// eslint-env es6

import { newGuid } from '@pressreader/utils';
import { baseUrl as svcBaseUrl, svcAuth } from '@pressreader/services';

import ndRes from 'deprecated/nd.res';
import ndAlert from 'nd.alert';
import { logError } from 'shared/errors/error.handlers';
import ndServicesApi from 'nd.services.api';
import { EXTERNAL_AUTH_ERROR, EXTERNAL_AUTH_TYPE } from '@pressreader/authentication-types';
import { expectExternalAuthorizationResult } from '@pressreader/authentication-api';

/**
 * Default implementation of authorization provider
 * request key -> token -> show authroization window -> process result over cookie -> sign in by key
 * Fits to the most cases (OAuth 2.0)
 */
class DefaultExternalAuthProvider {
    /**
     * Creates special session key for external authorization process
     */
    getRequestKey() {
        // this tweaks the flow a bit:
        // becasue key should be requested from the server and then returned to server as is
        // today format of key is guid, but it may change in the future
        // current implementation of native app already tweaks the request key.
        // so here we follow native app logic, which saves one extra request to the server api
        return newGuid();
    }

    /**
     * Obtains authentication token
     */
    getToken() {
        return svcAuth.token();
    }

    /**
     * Gets authorization request url
     *
     * @param {String} provider - provider name, e.g. "facebook"
     * @param {String} key - request key
     * @param {String} token - authentication token
     * @param {String} authtype - request key
     * @param {Object} [context] - context
     * @returns String url
     */
    getAuthorizationRequestUrl({ provider, key, token, authtype, context = {} }) {
        const baseUrl = svcBaseUrl();
        const path = '/externalauth/requestauthorization/';
        const tkn = encodeURIComponent(token);
        const params = {
            key,
            provider,
            authtype,
            command: context ? context.command : '',
            returnUrl: context?.returnUrl || null,
            linkToCurrentAccount: context && context.linkToCurrentAccount === true,
            libraryId: (context && context.library) || null,
            state: (context && context.state) || null,
        };

        const queryString = Object.keys(params)
            // es-lint-disable-next-line eqeqeq
            .filter(name => params[name] != null)
            .map(name => `${name}=${params[name]}`)
            .join('&');

        return `${baseUrl}${tkn}${path}?${queryString}`;
    }

    /**
     * Requests authoriation.
     * ATTENTION: It's essentail to have this call in the same task as user action.
     * So no async calls should be performed before this point.
     *
     * @abstract
     * @param {String} provider - provider name, e.g. "facebook"
     * @param {String} authtype - auth type
     * @param {String} key - request key
     * @param {String} url - authorization request url
     * @param {Object} [context] - context
     * @returns Promise.<AuthResult> url
     */
    requestAuthorization({ provider, url, context }) {
        // standalone window mode, out of pressreader site, used by native apps
        if (context.isWindowMode) {
            window.location.href = url;
            return Promise.resolve();
        }

        let wnd = window.open(url);

        return wnd
            ? expectExternalAuthorizationResult(wnd)
            : // if opening of new window requires user's interaction (browser restrictions)
              ndAlert()
                  .confirm(
                      ndRes.val(`Dialogs.Confirm.Logon.${provider}`, ndRes.val('Dialogs.Confirm.Logon')),
                      ndRes.val('Buttons.Cancel.Text'),
                      ndRes.val('Buttons.Login.Text'),
                  )
                  .then(() => {
                      wnd = window.open(url);
                      return expectExternalAuthorizationResult(wnd);
                  });
    }

    /**
     * Signs in externaly authorized user into PressReader
     * @returns {Promise.<SignInResult>}
     */
    signinByKey(key) {
        return ndServicesApi.post('auth', 'SigninByKey', JSON.stringify({ key }), {
            contentType: 'application/json',
        });
    }

    /**
     * Releases request key
     * @returns Promise.<Boolean> true if key is valid and released succefully
     */
    releaseRequestKey(key) {
        return (
            ndServicesApi
                // in fact, this API call just removes session key from temp storage
                .get('ExternalAuth', 'ValidateRequestKey', { key })
                .catch(logError)
        );
    }

    /**
     * Completes authoriation after request fulfilled.
     * Releases key, signs in the user into pressreader
     *
     * @abstract
     * @param {String} provider - provider name, e.g. "facebook"
     * @param {String} authType - authorziation type, see
     * @param {String} key - request key
     * @param {Object} [context] - context
     */
    completeAuthorization({ authtype, key, authResult }) {
        if (!authResult) {
            return Promise.resolve();
        }
        return authtype === EXTERNAL_AUTH_TYPE.SIGNUP
            ? this.signinByKey(key).then(signInResult => ({ authResult, signInResult }))
            : this.releaseRequestKey(key).then(() => ({ authResult }));
    }

    /**
     * Implements authorization flow
     *
     * @param {String} provider - provider name, e.g. "facebook"
     * @param {String} authtype
     * @param {Object} [context] - context, eg { command: 'post' }
     * @returns {Promise.<CombinedAuthResult|true|Error>}
     *  - authorization result,
     *  - true - positive result in case of sharing
     *  - authorization error
     */
    authorize({ provider, authtype = EXTERNAL_AUTH_TYPE.SHARING, context = {} }) {
        // obtain request key
        const key = this.getRequestKey({ context });

        // obtain auth token
        const token = this.getToken(key);

        // build authorization request url
        const url = this.getAuthorizationRequestUrl({ provider, authtype, key, token, context });

        // initiate request
        return (
            this.requestAuthorization({ provider, authtype, key, url, context })
                // complete authorization
                .then(authResult => this.completeAuthorization({ provider, authtype, key, authResult, context }))
                .catch(error => {
                    // hide unexpected errors from client
                    if (error && error.code === EXTERNAL_AUTH_ERROR.UNEXPECTED_ERROR) {
                        logError(error);
                        return Promise.reject();
                    }
                    return Promise.reject(error);
                })
        );
    }
}

export default DefaultExternalAuthProvider;
