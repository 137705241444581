import { interval } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { EXTERNAL_AUTH_ERROR } from '@pressreader/authentication-types';
import { localizedString } from '@pressreader/resources';
import { cookies, format } from '@pressreader/utils';

const externalResultParameterName = 'ext_sts';

const resultStatus = {
    SUCCESS: 'success',
    ERROR: 'error',
};

const errorCodes: Record<string, string> = {
    1: EXTERNAL_AUTH_ERROR.ACCESSTOKEN_EXPIRED,
    2: EXTERNAL_AUTH_ERROR.SIGNIN_ACCOUNT_EXISTS,
    3: EXTERNAL_AUTH_ERROR.LAST_SIGNIN_ACCOUNT,
    4: EXTERNAL_AUTH_ERROR.USERNAME_IN_USE,
    5: EXTERNAL_AUTH_ERROR.EXTERNAL_USERID_MISMATCH,
    6: EXTERNAL_AUTH_ERROR.LINKED_TO_ANOTHER_ACCOUNT,
    500: EXTERNAL_AUTH_ERROR.UNEXPECTED_ERROR,
};

const errorMessages: Record<string, string> = {
    [EXTERNAL_AUTH_ERROR.USERNAME_IN_USE]: localizedString('Errors.LogonEmailUnique', 'Account with this email already exists'),

    [EXTERNAL_AUTH_ERROR.LINKED_TO_ANOTHER_ACCOUNT]: localizedString(
        'Errors.ExternalProfileLinkedToAnotherAccount',
        'Profile cannot be linked, as it is already linked to another account',
    ),

    [EXTERNAL_AUTH_ERROR.UNEXPECTED_ERROR]: localizedString('Errors.UnexpectedError', 'Unexpected Error'),
};

function parseAuthResult(cookie: string) {
    const decoded = decodeURIComponent(cookie);

    // cookie values
    //----------------
    // Sucess:
    // success|<isNewUser: true/false>|<directive: list of integers, separated by ','>
    // Authorization error:
    // 'error|<error_code>'
    // 'error|5|<name of other user>|<name of target user>|<provider_display_name>'
    // decode a manually encoded symbol '|'
    const fields = decoded.split(/\|(?!%)/).map(v => v.replace(/\|%/g, '|'));

    if (fields.length === 0) {
        return new Error('Unexpected format of external call result');
    }

    if (fields[0] === resultStatus.SUCCESS) {
        return {
            isNewUser: fields[1] === 'true',
            directives: fields[2] ? fields[2].split(',') : undefined,
        };
    }
    const errorCode = errorCodes[fields[1]] || EXTERNAL_AUTH_ERROR.UNEXPECTED_ERROR;

    let errorMessage;

    if (errorCode === EXTERNAL_AUTH_ERROR.EXTERNAL_USERID_MISMATCH) {
        const [, , unrelatedUsername, relatedUsername, providerDisplayName] = fields;

        errorMessage = format(
            localizedString('ContentAccessDeniedException.ExternalAuthenticationDifferentAccessToken.Text'),
            providerDisplayName,
            unrelatedUsername,
            relatedUsername,
        );
    } else {
        errorMessage = errorMessages[errorCode];
    }

    return new Error(errorMessage || localizedString('Errors.UnexpectedError', 'Unexpected Error'));
}

function awaitWindowClosing(wnd: Window) {
    return new Promise(resolve => {
        // start polling
        const pollingIntervalMilliseconds = 100;
        interval(pollingIntervalMilliseconds)
            .pipe(
                map(() => !wnd || wnd.closed),
                filter(closed => closed),
                take(1),
            )
            .subscribe(resolve);
    });
}

async function expectExternalAuthorizationResult(wnd: Window) {
    await awaitWindowClosing(wnd);
    const result = cookies.has(externalResultParameterName)
        ? cookies.get(externalResultParameterName)
        : window.localStorage.getItem(externalResultParameterName);

    cookies.remove(externalResultParameterName);
    window.localStorage.removeItem(externalResultParameterName);

    if (!result) {
        throw new Error('Failed to get auth result.');
    }

    const authResult = parseAuthResult(result);

    return authResult === undefined || authResult instanceof Error ? Promise.reject(authResult) : Promise.resolve(authResult);
}

export { expectExternalAuthorizationResult };
