// eslint-env es6

import { registerImplementation } from 'externalsystems/signin';
import { EXTERNAL_SYSTEM, ExternalProvider } from '@pressreader/authentication-types';

// signin implementations
import Default from 'externalsystems/signin/default.signin.implementation';

registerImplementation(EXTERNAL_SYSTEM.FACEBOOK, new Default(ExternalProvider.FACEBOOK));
registerImplementation(EXTERNAL_SYSTEM.TWITTER, new Default(ExternalProvider.TWITTER));
registerImplementation(EXTERNAL_SYSTEM.GOOGLE, new Default(ExternalProvider.GOOGLE));
registerImplementation(EXTERNAL_SYSTEM.BELL, new Default(ExternalProvider.BELL));
registerImplementation(EXTERNAL_SYSTEM.PIANO, new Default(ExternalProvider.PIANO));
registerImplementation(EXTERNAL_SYSTEM.APPLE, new Default(ExternalProvider.APPLE));
registerImplementation(EXTERNAL_SYSTEM.AUTH0, new Default(ExternalProvider.AUTH0));
