import * as ko from 'knockout';
import { forEach } from 'lodash';

import { getThumbUrlByCidAndDateForWidth } from '@pressreader/images';
import { getPublication } from '@pressreader/src/catalog/services/catalog';

import '@pressreader/src/nd.issuescalendar.provider';

import issuesCalendar from 'nd.issuescalendar';

class PreviousIssuesThumbnailsViewModel {
    cid: string;
    maxPreviousIssues: number;
    thumbnailWidth: number;
    slug: KnockoutObservable<string>;
    previousIssues: KnockoutObservableArray<{
        issueDateString: string;
        issueDateStringFormatted: string;
        issueUrl: string;
        thumbnailUrl: string;
    }>;

    constructor(params: any) {
        if (!params.cid) {
            throw new Error("'cid' was not specified.");
        }

        if (params.maxPreviousIssues <= 0) {
            throw new Error("'maxPreviousIssues' must be greater than 0.");
        }

        if (params.thumbnailWidth <= 0) {
            throw new Error("'thumbnailWidth' must be greater than 0.");
        }

        this.cid = params.cid;
        this.maxPreviousIssues = params.maxPreviousIssues;
        this.thumbnailWidth = params.thumbnailWidth;

        this.previousIssues = ko.observableArray([]);
        this.slug = ko.observable();

        this.init();
    }

    init() {
        Promise.all([getPublication(this.cid), issuesCalendar.load(this.cid)]).then(([publication]) => {
            this.slug(publication.slug);

            const previousIssuesDates = issuesCalendar.getIssuesDates().slice(0, this.maxPreviousIssues);

            forEach(previousIssuesDates, previousIssueDate => {
                const thumbnailUrl = getThumbUrlByCidAndDateForWidth(this.cid, previousIssueDate, this.thumbnailWidth);
                const year = parseInt(previousIssueDate.substr(0, 4), 10);
                const month = parseInt(previousIssueDate.substr(4, 2), 10) - 1; // Month is a number from 0 to 11.
                const day = parseInt(previousIssueDate.substr(6, 2), 10);
                const dateStringFormatted = new Date(year, month, day).toDateString();
                const previousIssue = {
                    issueDateString: previousIssueDate,
                    issueDateStringFormatted: dateStringFormatted,
                    issueUrl: `/${this.slug()}/${previousIssueDate}`,
                    thumbnailUrl: thumbnailUrl,
                };

                this.previousIssues.push(previousIssue);
            });
        });
    }
}

/**
 * Knockout componente template is defined here because we don't load UI templates in Homepage.
 */
const template = `
<div data-bind="foreach: previousIssues">
    <a data-bind="attr: { href: $data.issueUrl, title: 'Read now!' }">
        <img data-bind="attr: { src: $data.thumbnailUrl }"/>
    </a>
    <p data-bind="text: $data.issueDateStringFormatted"></p>
</div>
`;

/**
 * Knockout component.
 * @example:
 * <previous-issues-thumbnails params="cid: cid, maxPreviousIssues: maxPreviousIssues, thumbnailWidth: thumbnailWidth" />
 */
const componentDefinition = {
    viewModel: PreviousIssuesThumbnailsViewModel,
    template,
};

const COMPONENT_NAME = 'previous-issues-thumbnails';

/**
 * Component auto registration
 */
if (!ko.components.isRegistered(COMPONENT_NAME)) {
    ko.components.register(COMPONENT_NAME, componentDefinition);
}

export default PreviousIssuesThumbnailsViewModel;
