import ndAuthentication from 'nd.authentication';
import ndLogger from 'nd.logger';
import ndUser from 'nd.user';

/*
    Default view model for LandingPage template.
    Please, update the following documentation after modifying this view model:
    https://pressreader.atlassian.net/wiki/spaces/BE/pages/2568356010/SE2Sky+LandingPageViewModel+for+LandingPage+template
*/

class LandingPageViewModel {
    isLoggedIn: KnockoutObservable<boolean>;

    constructor() {
        this.isLoggedIn = ko.observable(ndUser.isLogin());
        ndUser.onUserStateChanged(() => {
            this.isLoggedIn(ndUser.isLogin());
        });
    }

    public async showSignInDialog(): Promise<void> {
        try {
            await ndAuthentication.signIn();
            ndUser.redirectTo('OnSigningIn');
        } catch (e) {
            if (e?.userCancelled) {
                return;
            }
            ndLogger.error(e);
        }
    }
}

export default LandingPageViewModel;
