import { getIssueCalendarInfo } from '@pressreader/content-api';
import { IIssueCalendarInfo, IIssueCalendarInfoDay } from '@pressreader/content-types';

import ndLogger from 'nd.logger';

let _issuesCalendarData: { [key: string]: IIssueCalendarInfo };
let _cids: string[];
let _issuesDates: { [key: string]: string[] };
let _issueInfos: { [key: string]: IIssueCalendarInfoDay[] };

async function load(cids: string[], reload = false) {
    if (!_issuesDates && !reload && _cids === cids) {
        return _issuesDates;
    }

    try {
        _cids = cids;

        const data = await Promise.all(cids.map(cid => getIssueCalendarInfo(cid)));

        for (let index = 0; index < cids.length; index++) {
            _issuesCalendarData = {
                ..._issuesCalendarData,
                [cids[index]]: data[index],
            };

            const issueDates: string[] = [];
            const issueInfos: IIssueCalendarInfoDay[] = [];
            Object.keys(data[index].years).forEach(y => {
                Object.keys(data[index].years[y]).forEach(m => {
                    Object.keys(data[index].years[y][m]).forEach(d => {
                        issueDates.unshift(`${y}${m.padStart(2, '0')}${d.padStart(2, '0')}`);
                        issueInfos.unshift(data[index].years[y][m][d]);
                    });
                });
            });

            _issuesDates = {
                ..._issuesDates,
                [cids[index]]: issueDates,
            };

            _issueInfos = {
                ..._issueInfos,
                [cids[index]]: issueInfos,
            };
        }
    } catch (e) {
        ndLogger.error(e);
    }
}

export default {
    getData: (cid: string) => _issuesCalendarData[cid],
    getIssuesDates: () => _issuesDates,
    getIssueInfos: (cid: string) => _issueInfos[cid],
    load,
};
