import * as ko from 'knockout';
import { chunk, filter, forEach, groupBy } from 'lodash';

import { getDateFromIssueDateStr } from '@pressreader/content-utils';
import { getThumbUrlByCidAndDateForWidth } from '@pressreader/images';
import { getPublications } from '@pressreader/src/catalog/services/catalog';

import issuesCalendar from 'bundle/issuescalendar';

class PreviousIssuesThumbnailsGroupedViewModel {
    dateRangeDropDownOptions: Array<string>;
    maxIssuesPerRow: number;
    maxPreviousIssues: number;
    publications: Array<{ cid: string; editionName: string }>;
    thumbnailWidth: number;
    weekdaysClasses: Array<{ weekday: number; class: string }>;

    bootstrapColumnSize: KnockoutObservable<string>;
    dateRangeInDays: KnockoutObservable<number>;
    isLoadLessButtonVisible: KnockoutObservable<boolean>;
    isLoadMoreButtonVisible: KnockoutObservable<boolean>;
    loadMoreCounter: KnockoutObservable<number>;
    numberOfPreviousIssuesLoaded: KnockoutObservable<number>;
    oldestIssueDate: KnockoutObservable<Date>;
    previousIssues: KnockoutObservableArray<{
        cid: string;
        name: string;
        editionName: string;
        issueDate: Date;
        issueDateString: string;
        issueDateStringFormatted: string;
        issueUrl: string;
        thumbnailUrl: string;
    }>;
    previousIssuesGroupedByDate: KnockoutObservableArray<{
        date: Date;
        rows: {
            row: number;
            previousIssues: {
                cid: string;
                name: string;
                editionName: string;
                issueDate: Date;
                issueDateString: string;
                issueDateStringFormatted: string;
                issueUrl: string;
                thumbnailUrl: string;
            }[];
        }[];
    }>;
    showingResultsText: KnockoutObservable<string>;

    constructor(params: any) {
        this.validateParameters(params);

        this.dateRangeDropDownOptions = params.dateRangeDropDownOptions;
        this.maxIssuesPerRow = params.maxIssuesPerRow;
        this.maxPreviousIssues = params.maxPreviousIssues;
        this.publications = params.publications.map(publication => {
            publication.cid = publication.cid.toLowerCase();

            return publication;
        });
        this.thumbnailWidth = params.thumbnailWidth;
        this.weekdaysClasses = params.weekdaysClasses;

        this.dateRangeInDays = ko.observable();
        this.loadMoreCounter = ko.observable(1);
        this.previousIssues = ko.observableArray([]);
        this.previousIssuesGroupedByDate = ko.observableArray([]);

        this.oldestIssueDate = ko.computed(() => {
            if (isNaN(Number(this.dateRangeInDays()))) {
                return new Date(0);
            }

            const oldestIssueDate = new Date();

            oldestIssueDate.setDate(oldestIssueDate.getDate() - this.dateRangeInDays() * this.loadMoreCounter());

            return oldestIssueDate;
        });
        this.numberOfPreviousIssuesLoaded = ko.computed(() => {
            const loadedPreviousIssuesByDate = filter(this.previousIssuesGroupedByDate(), ({ date }) => date >= this.oldestIssueDate());
            let numberOfPreviousIssuesLoaded = 0;

            forEach(loadedPreviousIssuesByDate, previousIssuesByDate => {
                forEach(previousIssuesByDate.rows, row => {
                    numberOfPreviousIssuesLoaded += row.previousIssues.length;
                });
            });

            return numberOfPreviousIssuesLoaded;
        });
        this.showingResultsText = ko.computed(() => {
            return `Showing 1 - ${this.numberOfPreviousIssuesLoaded()} of ${this.previousIssues().length} results`;
        });
        this.isLoadLessButtonVisible = ko.computed(() => {
            return this.loadMoreCounter() > 1;
        });
        this.isLoadMoreButtonVisible = ko.computed(() => {
            return this.numberOfPreviousIssuesLoaded() < this.previousIssues().length;
        });

        this.loadPreviousIssues();
    }

    public getDateFormatted(date: Date): string {
        const longMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const longDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

        return `${longDays[date.getDay()]} ${longMonths[date.getMonth()]} ${date.getDate()} ${date.getFullYear()}`;
    }

    public getWeekdayClass(date: Date): string {
        return this.weekdaysClasses[date.getDay()].class;
    }

    public loadLessIssues(): void {
        if (this.loadMoreCounter() < 1) {
            throw new Error('Cannot load less issues.');
        }

        this.loadMoreCounter(this.loadMoreCounter() - 1);
    }

    public loadMoreIssues(): void {
        this.loadMoreCounter(this.loadMoreCounter() + 1);
    }

    private groupPreviousIssuesByDate(): void {
        this.previousIssuesGroupedByDate([]);

        const previousIssuesGroupedByDate = groupBy(this.previousIssues(), prev => prev.issueDate);

        forEach(previousIssuesGroupedByDate, (issues, date) => {
            const issuesByRow = chunk(issues, this.maxIssuesPerRow);
            const rows = new Array<{
                row: number;
                previousIssues: {
                    cid: string;
                    name: string;
                    editionName: string;
                    issueDate: Date;
                    issueDateString: string;
                    issueDateStringFormatted: string;
                    issueUrl: string;
                    thumbnailUrl: string;
                }[];
            }>();

            forEach(issuesByRow, (issues, row) => {
                rows.push({
                    row: row,
                    previousIssues: issues,
                });
            });

            this.previousIssuesGroupedByDate.push({
                date: new Date(date),
                rows: rows,
            });
        });
    }

    private async loadPreviousIssues(): Promise<void> {
        this.previousIssues([]);

        const cids = this.publications.map(publication => publication.cid.toLowerCase());

        Promise.all([getPublications(cids), issuesCalendar.load(cids)]).then(([publications]) => {
            const issuesDates = issuesCalendar.getIssuesDates();

            forEach(publications, publication => {
                const publicationPreviousIssuesDates = issuesDates[publication.cid];

                forEach(publicationPreviousIssuesDates, previousIssueDate => {
                    const issueDate = getDateFromIssueDateStr(previousIssueDate);
                    const thumbnailUrl = getThumbUrlByCidAndDateForWidth(publication.cid, previousIssueDate, this.thumbnailWidth);
                    const editionName = this.publications.find(element => element.cid === publication.cid).editionName;
                    const previousIssue = {
                        cid: publication.cid,
                        name: publication.name,
                        editionName: editionName,
                        issueDate: issueDate,
                        issueDateString: previousIssueDate,
                        issueDateStringFormatted: issueDate.toDateString(),
                        issueUrl: `/${publication.slug}/${previousIssueDate}`,
                        thumbnailUrl: thumbnailUrl,
                    };

                    this.previousIssues.push(previousIssue);
                });
            });

            this.groupPreviousIssuesByDate();
            this.previousIssuesGroupedByDate().sort((a, b) => b.date.getTime() - a.date.getTime());
            this.previousIssuesGroupedByDate(this.previousIssuesGroupedByDate());
        });
    }

    private validateParameters(params: any): void {
        if (!params.dateRangeDropDownOptions) {
            throw new Error("'dateRangeDropDownOptions' was not specified.");
        }
        if (params.dateRangeDropDownOptions.length === 0) {
            throw new Error("'dateRangeDropDownOptions' is empty.");
        }
        forEach(params.dateRangeDropDownOptions, (option: string) => {
            if (option.toLowerCase() !== 'all' && !Number.isInteger(parseFloat(option))) {
                throw new Error(`'dateRangeDropDownOptions' is invalid. Value (${option}) must be integer or 'All'.`);
            }
        });

        if (!params.maxIssuesPerRow) {
            throw new Error("'maxIssuesPerRow' was not specified.");
        }
        if (params.maxIssuesPerRow < 1 || params.maxIssuesPerRow > 4) {
            throw new Error("'maxIssuesPerRow' must be between 1 and 4.");
        }

        if (!params.maxPreviousIssues) {
            throw new Error("'maxPreviousIssues' was not specified.");
        }
        if (params.maxPreviousIssues <= 0) {
            throw new Error("'maxPreviousIssues' must be greater than 0.");
        }

        if (!params.publications) {
            throw new Error("'publications' was not specified.");
        }
        if (params.publications.length === 0) {
            throw new Error("'publications' is empty.");
        }
        forEach(params.publications, publication => {
            if (!publication.cid || !publication.editionName) {
                throw new Error("'publication.cid' or 'publication.editionName' is not defined.");
            }
        });

        if (params.thumbnailWidth <= 0) {
            throw new Error("'thumbnailWidth' must be greater than 0.");
        }

        if (!params.weekdaysClasses) {
            throw new Error("'weekdaysClasses' was not specified.");
        }
        if (params.weekdaysClasses.length === 0) {
            throw new Error("'weekdaysClasses' is empty.");
        }
        forEach(params.weekdaysClasses, weekdayClass => {
            if ((weekdayClass.weekday !== 0 && !weekdayClass.weekday) || !weekdayClass.class) {
                throw new Error("'weekdaysClasses.weekday' or 'weekdaysClasses.class' is not defined.");
            }
        });
    }
}

/**
 * Knockout component template is defined here because we don't load UI templates in Homepage.
 */
const template = `
    <label for="date-range-dropdown">Date range in days </label>
    <select id="date-range-dropdown" data-bind="options: dateRangeDropDownOptions,
                                                         value: dateRangeInDays,
                                                         event: { change: loadPreviousIssues }">
    </select>
    <span data-bind="text: showingResultsText"></span>

    <div data-bind="foreach: previousIssuesGroupedByDate">
        <!-- ko if: $data.date >= $component.oldestIssueDate() -->
        <section>
            <div class="album py-5">
                <div class="container">
                    <h1 class="jumbotron-heading" data-bind="text: $component.getDateFormatted($data.date)"></h1>
                    <hr>
                    <br>
                    <!-- ko foreach: $data.rows -->
                    <div class="row">
                        <!-- ko foreach: $data.previousIssues -->
                        <div data-bind="attr: { 'class': $component.getWeekdayClass($parents[1].date) }">
                            <a data-bind="attr: { href: $data.issueUrl, title: 'Read now!' }">
                                <img data-bind="attr: { src: $data.thumbnailUrl }">
                            </a>
                            <p class="pre-title" data-bind="text: $data.name"></p>
                            <h5 class="title" data-bind="text: $data.editionName"></h5>
                            <a class="read-now" data-bind="attr: { href: $data.issueUrl, title: 'Click here to read' }">
                                Read now &nbsp;
                                <img class="read-now-icon" src="images/chevron.svg">
                            </a>
                        </div>
                        <!-- /ko -->
                    </div>
                    <!-- /ko -->
                </div>
            </div>
        </section>
        <!-- /ko -->
    </div>
    <span data-bind="text: showingResultsText"></span>
    </br>
    <!-- ko if: isLoadLessButtonVisible -->
    <button data-bind="click: loadLessIssues">Load less</button>
    <!-- /ko -->
    <!-- ko if: isLoadMoreButtonVisible -->
    <button data-bind="click: loadMoreIssues">Load more</button>
    <!-- /ko -->
    `;

/**
 * Knockout component.
 * Documentation: https://pressreader.atlassian.net/wiki/spaces/BE/pages/1729429530/SE2Sky+Previous+Issues+Thumbnails+Grouped+KO+Component
 *
 * @example:
 * <previous-issues-thumbnails-grouped params="dateRangeDropDownOptions: ['5', '4', '3', '2', '1', 'All'],
 *                                             maxIssuesPerRow: 6,
 *                                             maxPreviousIssues: 100,
 *                                             publications: [
 *                                                 {cid: '1162', editionName: 'Bricks and Mortar Edition Name'},
 *                                                 {cid: '1163', editionName: 'The Sunday Times Edition Name'},
 *                                                 {cid: '1165', editionName: 'Business Edition Name'},
 *                                                 {cid: '1148', editionName: 'The Times Edition Name'},
 *                                             ],
 *                                             thumbnailWidth: 200,
 *                                             weekdaysClasses: [
 *                                                 {weekday: 0, class: 'col-md-3 col-sm-6 col-6'},
 *                                                 {weekday: 1, class: 'col-lg-4 col-md-6 col-sm-6 col-6'},
 *                                                 {weekday: 2, class: 'col-lg-4 col-md-6 col-sm-6 col-6'},
 *                                                 {weekday: 3, class: 'col-lg-4 col-md-6 col-sm-6 col-6'},
 *                                                 {weekday: 4, class: 'col-lg-4 col-md-6 col-sm-6 col-6'},
 *                                                 {weekday: 5, class: 'col-lg-4 col-md-6 col-sm-6 col-6'},
 *                                                 {weekday: 6, class: 'col-lg-4 col-md-6 col-sm-6 col-6'},
 *                                             ]">
 * </previous-issues-thumbnails-grouped>
 */
const componentDefinition = {
    viewModel: PreviousIssuesThumbnailsGroupedViewModel,
    template,
};

const COMPONENT_NAME = 'previous-issues-thumbnails-grouped';

/**
 * Component auto registration
 */
if (!ko.components.isRegistered(COMPONENT_NAME)) {
    ko.components.register(COMPONENT_NAME, componentDefinition);
}

export default PreviousIssuesThumbnailsGroupedViewModel;
