// eslint-env es6

import * as ko from 'knockout';
import dialog from 'nd.ui.dialog';
import { DialogStatus } from 'nd.ui.dialogstatus';
import ndAlert from 'nd.alert';
import ndRes from 'deprecated/nd.res';
import api from 'nd.services.api';
import { logError } from 'shared/errors/error.handlers';

function showError() {
    ndAlert().show(ndRes.val('Errors.SignInFailed'));
}

class InstapaperAuthProvider {
    authorize({ provider }) {
        return new Promise((resolve, reject) => {
            const parentDialog = dialog.getParent();
            const signInDialog = dialog.createInstance();

            signInDialog.onHide((_, result) => {
                if (result && result.status === DialogStatus.Ok) {
                    if (parentDialog) {
                        parentDialog.hide();
                    }
                    resolve(true);
                } else {
                    if (parentDialog) {
                        parentDialog.restore();
                    }
                    reject();
                }
            });

            const viewModel = {
                userName: ko.observable(),
                password: ko.observable(),
                submit: () => {
                    const post = { provider, userName: viewModel.userName(), password: viewModel.password() };
                    api.post('externalauth', 'AuthorizeXAuth', post)
                        .then(response => {
                            if (response && response.Status === 0) {
                                signInDialog.hide(DialogStatus.Ok);
                            } else {
                                showError();
                            }
                        })
                        .catch(logError);
                },
                close: () => signInDialog.hide(DialogStatus.Close),
            };

            signInDialog.show({
                templateName: 'v7.Client.Dialogs.xAuth.Signin',
                model: viewModel,
            });
        });
    }
}

export default InstapaperAuthProvider;
