import 'shim/globalshim';
import 'jquery';
import 'knockout';
import 'shim/home.mappings';
import '@pressreader/src/externalsystems/providers.init';
import '@pressreader/src/externalsystems/signin.init';
import 'nd.res.provider.pd';
import '@pressreader/src/nd.widgets';
import 'issues/components/previous.issues.thumbnails';
import 'issues/components/previous.issues.thumbnails.grouped';
import 'analytics/analytics';
import 'ko/bindings/all';
import '@pressreader/src/nd.knockout.extenders';

import LandingPageViewModel from 'pagesview/viewmodels/landingpageviewmodel';
import { init as initAuth } from 'authentication/nd.authentication';
import { svcAuth, baseUrl } from '@pressreader/services';
import { init as initAppCore, startAppAction } from '@pressreader/appcore';
import { dispatch } from '@pressreader/appstore';
import { load as loadScripts } from 'nd.scripts';

baseUrl(window.serviceUrl);
svcAuth.initAuthTickets();

// sets scripts base url to load ondemand scripts
__webpack_public_path__ = window.scriptBaseUrl;

svcAuth.initAuthTickets();

async function init() {
    await initAppCore();

    initAuth();

    loadScripts();

    // start application flow
    dispatch(startAppAction({}));
}

init();

document.addEventListener(
    'DOMContentLoaded',
    () => {
        const landingPageElement = document.getElementById('landing-page-wrapper');

        if (landingPageElement) {
            const landingPageViewModel = new LandingPageViewModel();

            ko.applyBindings(landingPageViewModel, landingPageElement);
        }
    },
    {
        once: true,
    },
);
