import * as $ from 'jquery';
import * as ko from 'knockout';

import ndConfig from '@pressreader/src/deprecated/nd.config';
import { defineShim } from '@pressreader/src/shim/requirejs';

import imageSource from 'nd.imagesource';
import ndUser from 'nd.user';
import ndAuthentication from 'authentication/nd.authentication';

defineShim('jquery', function () {
    return $;
});
defineShim('knockout', function () {
    return ko;
});
defineShim('nd.user', function () {
    return ndUser;
});
defineShim('nd.authentication', function () {
    return ndAuthentication;
});
defineShim('nd.config', function () {
    return ndConfig;
});
defineShim('nd.imagesource', function () {
    return imageSource;
});
